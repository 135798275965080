.header {
  position: fixed;
  top: 0px;
  height: 90px;
  width: 100%;
  background-color: #000;
  opacity: 0.75;
  z-index: 10;
  transition: 0.3s ease-in;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px 10px 30px;
}
.navbar__logo {
  width: 20rem;
  padding-bottom: 2rem;
  color: #fff;
  font-family: "montecarlo";
  font-size: 4rem;
  margin-left: 2rem;
}
.navbar__list {
  display: flex;
  list-style: none;
  text-transform: uppercase;
  margin-right: 2rem;
}
.navbar__item {
  white-space: nowrap;
}
.navbar__link {
  font-size: 1.5rem;
  text-decoration: none;
  color: #fff;
  text-align: center;
  padding: 10px;
  margin: 0px 5px;
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: transparent;
  transition: 250ms;
}
.navbar__link:hover {
  border-color: #fff;
  color: var(--lightBlue);
  transition: 250ms;
}

.navbar__menu {
  color: #fff;
  cursor: pointer;
  display: none;
  margin-right: 2rem;
}

@media screen and (max-width: 800px) {
  .navbar__list {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    padding-left: 0;
    margin-bottom: 0;
    top: 80px;
    left: -100%;
    width: 100%;
    height: 300px;
    transition: all 0.5s;
  }

  .navbar__list--active {
    top: 40px;
    left: 0;

    margin-top: 5rem;
  }
  .navbar__item {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    flex: 1;
  }
  .navbar__link {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    margin: 0px;
    border-width: 0px;
    background-color: #000;
    opacity: 0.75;
  }
  .navbar__link:hover {
    opacity: 1;
    color: var(--lightBlue);
    transition: 250ms;
  }
  .navbar__menu {
    display: block;
  }
}
