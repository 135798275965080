.formulario {
  background-color: var(--grisClaro);
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
}
.container .titulo {
  color: var(--gris);
  text-transform: uppercase;
  text-align: center;
  font-weight: 500;
  margin-top: 10rem;
  margin-bottom: 5rem;
}
.form-body {
  width: 100%;
}

.form-body .input {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--gris);
  background-color: transparent;
  margin-bottom: 2rem;
}

.form-body button {
  padding: 0.5rem 5rem;
  font-weight: 500;
  border: none;
}
.form-body button:hover {
  color: var(--lightBlue);
  border-bottom: 1px solid var(--grisOscuro);
}
.error {
  color: #ff0000;
}
.exito {
  color: #015c01;
}
.pasajeros {
  display: flex;
  margin-top: 2rem;
}

.pasajeros-fechas-input {
  display: flex;
  margin-top: 2rem;
  gap: 1rem;
}
@media (min-width: 768px) {
  .pasajeros-fechas-input {
    gap: 2rem;
  }
}

.label {
  margin-right: 2rem;
}
.textarea {
  margin-top: 1rem;
  height: 15rem;
}

input:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}

.select-css {
  font-size: 16px;
  font-family: var(--primary);
  font-weight: 400;
  color: #444;
  line-height: 1.3;
  padding: 0.4em 1.4em 0.3em 0.8em;
  width: 100px;
  max-width: 130px;
  box-sizing: border-box;
  margin: 10px;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.03);
  border-radius: 0.3em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--grisClaro);
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}
.select-css:hover {
  border-color: #888;
}
.select-css:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
@media (min-width: 768px) {
  .select-css {
    width: 300px;
  }
}
input[type="date"] {
  display: block;
  position: relative;
  padding: 0.3rem 3.5rem 0.3rem 0.75rem;

  font-size: 1.3rem;
  font-family: var(--primary);
  font-weight: 500;

  border: 1px solid var(--gris);
  border-radius: 0.5rem;
  background: var(--grisClaro)
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='22' viewBox='0 0 20 22'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23688EBB' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' transform='translate(1 1)'%3E%3Crect width='18' height='18' y='2' rx='2'/%3E%3Cpath d='M13 0L13 4M5 0L5 4M0 8L18 8'/%3E%3C/g%3E%3C/svg%3E")
    right 1rem center no-repeat;

  cursor: pointer;
}
input[type="date"]:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}

::-webkit-datetime-edit-text {
  opacity: 0;
}
::-webkit-clear-button,
::-webkit-inner-spin-button {
  display: none;
}
::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 2.5rem;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;

  opacity: 0;
  cursor: pointer;

  color: var(--lightBlue);
  background: var(--lightBlue);
}
