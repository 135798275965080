.hero {
  background-image: url(../../assets/hero.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
  height: 100vh;
}
.hero::before {
  content: "";
  background-color: var(--negro);
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;
}
.hero__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}
