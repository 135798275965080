:root {
  --primario: ;
  --lightBlue: #00cdfe;
  --gris: #889098;
  --blanco: #fff;
  --negro: #000;
  --grisClaro: #ebeaeb;
  --grisOscuro: #3a3d3d;

  --separacion: 5rem;

  --fuentePrincipal: "Roboto", sans-serif;
  --fuenteSecundaria: ;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; /* 1REM = 10px */
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: var(--fuentePrincipal);
  font-size: 1.6rem;
  line-height: 2;
  /* background-color: var(--grisClaro); */
}

/* ----- ----- Globales ----- ----- */

h1,
h2,
h3 {
  font-weight: 900;
  font-family: var(--fuentePrincipal);
  margin: calc(var(--separacion) / 2) 0;
}

h1 {
  font-size: 4.4rem;
}

h2 {
  font-size: 3.6rem;
  color: var(--gris);
}

h3 {
  font-size: 2.8rem;
}

img {
  max-width: 100%;
  display: block;
  height: auto;
}

/* ----- ----- Utilidades ----- ----- */

.text-center {
  text-align: center;
}
